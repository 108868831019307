<template>
  <div>
    <!--      Forms-->
    <div class="max-w-3xl mx-auto min-h-screen">
      <div class="mt-10 pb-10">
        <Form
          v-for="(step, index) in step_meta"
          v-if="+index === +current_step"
          :id="index"
          :key="index"
          :demo-form="false"
          :forms="step[0].fields"
          :numberOfSteps="step_meta.length - 1"
          :step="step"
          classes="shadow rounded-md"
          form-type="IB-individual"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Form from "@/views/register/Form";
import store from "@/store";

export default {
  name: "IndividualForms",
  data() {
    return {
      whitelabel: process.env.VUE_APP_WHITELABEL,
    };
  },
  components: {
    Form,
  },
  computed: {
    current_step() {
      if (!localStorage.getItem("step")) {
        localStorage.setItem("step", "0");
      }
      return this.$store.getters.show_next_step;
    },
    step_meta() {
      const t = this.$t.bind(this);
      let fields = [
        [
          {
            kind: "title",
            type: "title",
            title: t("personal-details"),
            fields: [
              {
                field: "title",
                key: "title",
                label: t("Title"),
                placeholder: "Title",
                kind: "select",
                type: "select",
                needed: true,
                fieldId: "title",
                choices: [
                  { value: 1, display_name: t("Mr") },
                  { value: 2, display_name: t("Ms") },
                  {
                    value: 3,
                    display_name: t("Mrs"),
                  },
                  { value: 4, display_name: t("Miss") },
                  { value: 5, display_name: t("Dr") },
                  {
                    value: 6,
                    display_name: t("Other"),
                  },
                ],
              },
              {
                field: "first_name",
                key: "first_name",
                label: t("first-name-20db0bfeecd8fe60533206a2b5e9891a"),
                placeholder: t("first-name-20db0bfeecd8fe60533206a2b5e9891a"),
                kind: "text",
                type: "text",
                needed: true,
                half: true,
                fieldId: "fname",
              },
              {
                field: "last_name",
                key: "last_name",
                label: t("last-name-8d3f5eff9c40ee315d452392bed5309b"),
                placeholder: t("last-name-8d3f5eff9c40ee315d452392bed5309b"),
                kind: "text",
                type: "text",
                needed: true,
                half: true,
                fieldId: "lname",
              },
              {
                field: "email",
                key: "email",
                label: t("Email"),
                placeholder: t("Email"),
                kind: "email",
                type: "email",
                needed: true,
                fieldId: "email",
              },
              {
                field: "password",
                key: "password",
                label: t("please-choose-a-password"),
                placeholder: t("please-choose-a-password"),
                kind: "password",
                type: "password",
                icon: "icon-password",
                needed: true,
                fieldId: "password",
              },
              {
                field: "password_confirmation",
                key: "password_confirmation",
                label: t("password-confirmation"),
                placeholder: t("enter-password-again-to-confirm"),
                kind: "password",
                type: "password",
                icon: "icon-password",
                needed: true,
                fieldId: "pconfirmation",
              },
              {
                field: "date_of_birth",
                key: "date_of_birth",
                label: t("date-of-birth"),
                placeholder: "DD / MM / YYYY",
                kind: "date_of_birth",
                type: "text",
                needed: true,
                fieldId: "dobirth",
              },
              {
                field: "skype",
                key: "skype",
                label: t("skype-id-optional"),
                placeholder: t("skype-id"),
                kind: "text",
                type: "text",
                needed: false,
                fieldId: "skype",
              },
              {
                field: "telephone",
                key: "telephone",
                label: t("phone-number-1f8261d17452a959e013666c5df45e07"),
                placeholder: t("phone-number-1f8261d17452a959e013666c5df45e07"),
                kind: "telephone",
                type: "telephone",
                needed: true,
                fieldId: "phone",
              },
              {
                field: "addr_country_ib",
                key: "addr_country",
                label: t("Country"),
                placeholder: t("country-name"),
                kind: "select",
                type: "select",
                needed: true,
                country: true,
                fieldId: "country",
                choices: store.getters.get_countries,
              },
              {
                field: "nationality_ib",
                key: "nationality",
                label: t("Nationality"),
                placeholder: t("select-nationality"),
                kind: "select",
                type: "select",
                needed: true,
                choices: store.getters.get_countries,
                fieldId: "nationality",
              },
              {
                field: "addr_zip",
                key: "addr_zip",
                label: "Postcode",
                placeholder: "Postcode",
                kind: "text",
                type: "text",
                needed: true,
                fieldId: "azip",
              },
              {
                field: "addr_street",
                key: "addr_street",
                label: t("address-line-1"),
                placeholder: t("address-line-1"),
                kind: "text",
                type: "text",
                needed: true,
                fieldId: "addr1",
              },
              {
                field: "addr_line_2",
                key: "addr_line_2",
                label: t("address-line-2"),
                placeholder: t("address-line-2"),
                kind: "text",
                type: "text",
                needed: false,
                fieldId: "addr2",
              },
              {
                field: "addr_city",
                key: "addr_city",
                label: t("city-or-town"),
                placeholder: t("city-or-town"),
                kind: "text",
                type: "text",
                needed: true,
                fieldId: "city",
              },
              {
                field: "target_region",
                key: "target_region",
                label: t("what-geographic-region-you-will-be-mainly-targeting"),
                placeholder: t(
                  "what-geographic-region-you-will-be-mainly-targeting"
                ),
                kind: "text",
                type: "text",
                needed: true,
                fieldId: "tregion",
              },
              {
                field: "currency",
                key: "currency",
                label: t("Currency"),
                placeholder: t("Currency"),
                kind: "select",
                type: "select",
                needed: true,
                fieldId: "currency",
                choices: [
                  { value: "GBP", display_name: "GBP" },
                  { value: "USD", display_name: "USD" },
                  {
                    value: "EUR",
                    display_name: "EUR",
                  },
                ],
              },
              {
                field: "terms_and_conditions",
                key: "terms_and_conditions",
                label:
                  "I have read, understood and accepted the Privacy Policy, Risk Disclosures and Terms & Conditions",
                kind: "checkbox",
                type: "checkbox",
                needed: true,
                fieldId: "tconditions",
              },
            ],
          },
        ],
      ];
      if (
        this.whitelabel === "EagleGlobalMarkets" ||
        this.whitelabel === "TradeCoreUK"
      ) {
        let currencyKey = fields[0][0].fields.findIndex(
          (x) => x.key === "currency"
        );
        let currency2 = {
          field: "currency",
          key: "currency",
          label: t("Currency"),
          placeholder: t("Currency"),
          kind: "select",
          type: "select",
          needed: true,
          fieldId: "currency",
          choices: [{ value: "USD", display_name: "USD" }],
        };
        fields[0][0].fields[currencyKey] = currency2;
      }
      if (this.whitelabel === "Blackstone") {
        let confPass = fields[0][0].fields.findIndex(
          (x) => x.key === "currency"
        );
        let pass2 = {
          field: "currency",
          key: "currency",
          label: t("Currency"),
          placeholder: t("Currency"),
          kind: "select",
          type: "select",
          needed: true,
          fieldId: "currency",
          choices: [
            { value: "USD", display_name: "USD" },
            { value: "ZAR", display_name: "ZAR" },
          ],
        };
        fields[0][0].fields[confPass] = pass2;
      }
      if (this.whitelabel === "PolarisMarkets") {
        //   remove fields "target_region", "postcode", "region", "skype"
        const fieldsToRemove = [
          "target_region",
          "target_region",
          "skype",
          "currency",
          "terms_and_conditions",
        ];
        fields[0][0].fields = fields[0][0].fields.filter(
          (field) => !fieldsToRemove.includes(field.key)
        );
        // add fields "UScitizen"
        fields[0][0].fields.push({
          field: "usa_tax_resident",
          key: "usa_tax_resident",
          label: "Are you a citizen or resident of the\n" + "United States?",
          kind: "select",
          type: "select",
          needed: true,
          fieldId: "usa_tax_resident",
          choices: [
            { value: "Yes", display_name: "Yes" },
            { value: "No", display_name: "No" },
          ],
        });
        //   change terms and conditions label
        const string =
          "By completing this application form and clicking the submit button, I confirm that I have read, understood, and agreed to the aforementioned statements. I acknowledge that my typed name in the designated field serves as my legal signature, equivalent to my handwritten signature.";

        const personal_to_change = {
          field: "terms_and_conditions",
          key: "terms_and_conditions",
          label:
            "By submitting this form, I acknowledge and agree to the Terms of Business and related documents of POLARIS, recognizing that this constitutes a legally binding contractual agreement. I confirm that I fully comprehend the inherent nature and risks involved in trading Forex, CFDs, and other derivatives. Furthermore, I have thoroughly read and understood the Terms and Conditions, Order Execution Policy, Privacy Policy, and Risk Warning provided by POLARIS, which can be accessed online at www.polaris.com. By submitting this application, I consent to abide by the terms stated in these documents.<br/>\n<br/>\nAdditionally, I confirm the following:<br/>\n<br/>\nBy completing this application form and clicking the submit button, I confirm that I have read, understood, and agreed to the aforementioned statements. I acknowledge that my typed name in the designated field serves as my legal signature, equivalent to my handwritten signature.",

          kind: "checkbox",

          type: "checkbox",
          needed: true,
          fieldId: "tconditions",
        };
        fields[0][0].fields.push(personal_to_change);
      }
      if (this.whitelabel === "OneTradingMarkets") {
        let skypeField = fields[0][0].fields.findIndex(
          (x) => x.key === "skype"
        );
        fields[0][0].fields.splice(skypeField, 1);

        let postcodeField = fields[0][0].fields.findIndex(
          (x) => x.key === "addr_zip"
        );
        fields[0][0].fields.splice(postcodeField, 1);

        let currencyKey = fields[0][0].fields.findIndex(
          (x) => x.key === "currency"
        );
        let currency2 = {
          field: "currency",
          key: "currency",
          label: t("Currency"),
          placeholder: t("Currency"),
          kind: "select",
          type: "select",
          needed: true,
          fieldId: "currency",
          choices: [{ value: "USD", display_name: "USD" }],
        };
        fields[0][0].fields[currencyKey] = currency2;

        let termAndCon = fields[0][0].fields.findIndex(
          (x) => x.key === "terms_and_conditions"
        );
        let termsAndCondition2 = {
          field: "terms_and_conditions",
          key: "terms_and_conditions",
          label:
            'I have read, understood and accepted the <a href="https://onetradingmarkets.com/privacy-policy-2/" target="_blank">Privacy Policy</a>, Risk Disclosures and <a href="https://onetradingmarkets.com/terms-conditions/" target="_blank">Terms & Conditions</a>',
          kind: "checkbox",
          type: "checkbox",
          needed: true,
          fieldId: "tconditions",
        };
        fields[0][0].fields[termAndCon] = termsAndCondition2;
      }

      return fields;
    },
  },

  created() {
    this.$store.dispatch("countries");
  },
};
</script>
