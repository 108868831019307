<template>
  <div class="ib-idividual-register-vue">
    <Navigation
      :show-language-switcher="true"
      :show-user-menu="false"
      :showLogin="true"
    />
    <individual-register-finsa
      v-if="
        whitelabel === 'TD365' ||
        whitelabel === 'TDSouthAfrica' ||
        whitelabel === 'Blackstone' ||
        whitelabel === 'OneTradingMarkets' ||
        whitelabel === 'EagleGlobalMarkets' ||
        whitelabel === 'TradeCoreUK' ||
        whitelabel === 'PolarisMarkets'
      "
    >
    </individual-register-finsa>
    <individual-register-icm v-else></individual-register-icm>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "@/components/Footer";
import Navigation from "@/components/Navigation";
import IndividualRegisterIcm from "@/views/ib/register/IndividualRegisterIcm";
import IndividualRegisterFinsa from "@/views/ib/register/IndividualRegisterFinsa";

export default {
  name: "IndividualForms",
  data() {
    return {
      whitelabel: process.env.VUE_APP_WHITELABEL,
    };
  },
  components: {
    Footer,
    Navigation,
    IndividualRegisterIcm,
    IndividualRegisterFinsa,
  },
};
</script>
