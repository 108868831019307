<template>
  <div>
    <!--      steps-->
    <div class="bg-white lg:border-t lg:border-b lg:border-gray-200 mx-auto">
      <nav class="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
        <ul
          class="rounded-md overflow-hidden lg:flex lg:border-l lg:border-r lg:border-gray-200 lg:rounded-none"
        >
          <li
            v-for="(step, index) in step_meta"
            :key="index"
            class="relative overflow-hidden lg:flex-1"
          >
            <div
              class="border border-gray-200 overflow-hidden border-b-0 rounded-t-md lg:border-0"
            >
              <a class="group">
                <div
                  class="absolute top-0 left-0 w-1 h-full bg-transparent lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"
                ></div>
                <div
                  class="px-6 py-5 flex items-start text-sm leading-5 font-medium space-x-4"
                >
                  <div
                    v-if="index === $store.getters.show_next_step"
                    class="absolute top-0 left-0 w-1 h-full bg-indigo-600 lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"
                  ></div>
                  <div v-if="index < step" class="flex-shrink-0">
                    <div
                      class="w-10 h-10 flex items-center justify-center bg-indigo-600 rounded-full"
                    >
                      <!-- Heroicon name: check -->
                      <svg
                        class="w-6 h-6 text-white"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          clip-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          fill-rule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                  <div v-else class="flex-shrink-0">
                    <div
                      :class="
                        index === $store.getters.show_next_step
                          ? 'border-indigo-600'
                          : 'border-gray-300'
                      "
                      class="w-10 h-10 flex items-center justify-center border-2 rounded-full"
                    >
                      <p
                        :class="
                          index === $store.getters.show_next_step
                            ? 'text-indigo-600'
                            : 'text-gray-400'
                        "
                      >
                        {{ index + 1 }}
                      </p>
                    </div>
                  </div>
                  <div class="mt-0.5 min-w-0">
                    <h3
                      :class="
                        index === $store.getters.show_next_step
                          ? 'active-font-color'
                          : ''
                      "
                      class="text-xs leading-4 font-semibold uppercase tracking-wide"
                    >
                      <span v-if="index <= $store.getters.show_next_step">
                        {{ step[0].title }}
                      </span>
                      <span v-else>
                        {{ step[0].title }}
                      </span>
                    </h3>
                  </div>
                </div>
              </a>
            </div>
            <div
              v-if="0 < index"
              class="hidden absolute top-0 left-0 w-3 inset-0 lg:block"
            >
              <svg
                class="h-full w-full text-gray-300"
                fill="none"
                preserveAspectRatio="none"
                viewBox="0 0 12 82"
              >
                <path
                  d="M0.5 0V31L10.5 41L0.5 51V82"
                  stroke="currentcolor"
                  vector-effect="non-scaling-stroke"
                />
              </svg>
            </div>
          </li>
        </ul>
      </nav>
    </div>

    <!--      Forms-->
    <div class="max-w-3xl mx-auto min-h-screen">
      <div class="mt-10 pb-10">
        <Form
          v-for="(step, index) in step_meta"
          v-if="index === $store.getters.show_next_step"
          :id="index"
          :key="index"
          :demo-form="false"
          :forms="step[0].fields"
          :numberOfSteps="step_meta.length - 1"
          :step="step"
          classes="shadow rounded-md"
          form-type="IB-individual"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Form from "@/views/register/Form";
import store from "@/store";

export default {
  name: "IndividualForms",
  components: {
    Form,
  },
  computed: {
    step_meta() {
      const t = this.$t.bind(this);
      let fields = [
        [
          {
            kind: "title",
            type: "title",
            title: t("personal-details"),
            fields: [
              {
                field: "first_name",
                key: "first_name",
                label: t("first-name-20db0bfeecd8fe60533206a2b5e9891a"),
                placeholder: t("first-name-20db0bfeecd8fe60533206a2b5e9891a"),
                kind: "text",
                type: "text",
                needed: true,
                half: true,
                fieldId: "fname",
              },
              {
                field: "last_name",
                key: "last_name",
                label: t("last-name-8d3f5eff9c40ee315d452392bed5309b"),
                placeholder: t("last-name-8d3f5eff9c40ee315d452392bed5309b"),
                kind: "text",
                type: "text",
                needed: true,
                half: true,
                fieldId: "lname",
              },
              {
                field: "nationality_ib",
                key: "nationality",
                label: t("Nationality"),
                placeholder: t("select-nationality"),
                kind: "select",
                type: "select",
                needed: true,
                choices: store.getters.get_countries,
                fieldId: "nationality",
              },
              {
                field: "addr_street",
                key: "addr_street",
                label: t("address-line-1"),
                placeholder: t("address-line-1"),
                kind: "text",
                type: "text",
                needed: true,
                fieldId: "addr1",
              },
              {
                field: "addr_line_2",
                key: "addr_line_2",
                label: t("address-line-2"),
                placeholder: t("address-line-2"),
                kind: "text",
                type: "text",
                needed: false,
                fieldId: "addr2",
              },
              {
                field: "addr_city",
                key: "addr_city",
                label: t("city-or-town"),
                placeholder: t("city-or-town"),
                kind: "text",
                type: "text",
                needed: true,
                fieldId: "city",
              },
              // {
              //   field: 'addr_zip',
              //   key: 'addr_zip',
              //   label: t('Postcode'),
              //   placeholder: t('Postcode'),
              //   kind: 'postcode',
              //   type: 'postcode',
              //   needed: true,
              //   not_country: 'IE',
              //   fieldId: 'postcode'
              // },
              {
                field: "addr_country_ib",
                key: "addr_country",
                label: t("Country"),
                placeholder: t("country-name"),
                kind: "select",
                type: "select",
                needed: true,
                country: true,
                fieldId: "country",
                choices: store.getters.get_countries,
              },
              {
                field: "telephone",
                key: "telephone",
                label: t("phone-number-1f8261d17452a959e013666c5df45e07"),
                placeholder: t("phone-number-1f8261d17452a959e013666c5df45e07"),
                kind: "telephone",
                type: "telephone",
                needed: true,
                fieldId: "phone",
              },
              {
                field: "email",
                key: "email",
                label: t("Email"),
                placeholder: t("Email"),
                kind: "email",
                type: "email",
                needed: true,
                fieldId: "email",
              },
              {
                field: "password",
                key: "password",
                label: t("please-choose-a-password"),
                placeholder: t("please-choose-a-password"),
                kind: "password",
                type: "password",
                icon: "icon-password",
                needed: true,
                fieldId: "password",
              },
              {
                field: "website",
                key: "website",
                label: t("website-optional"),
                placeholder: t("http-www-company-com"),
                kind: "url",
                type: "url",
                needed: false,
                fieldId: "website",
              },
              {
                field: "skype",
                key: "skype",
                label: t("skype-id-optional"),
                placeholder: t("skype-id"),
                kind: "text",
                type: "text",
                needed: false,
                fieldId: "skype",
              },
              {
                field: "preferred_language",
                key: "preferred_language",
                label: t("preferred-language"),
                placeholder: t("preferred-language"),
                kind: "select",
                type: "select",
                choices: [
                  { value: "en", display_name: t("English") },
                  { value: "ar", display_name: t("Arabic") },
                  { value: "fa", display_name: t("Persian") },
                  { value: "zh-hans", display_name: t("chinese-simplified") },
                  { value: "zh-hant", display_name: t("chinese-traditional") },
                  { value: "nl", display_name: t("Dutch") },
                  { value: "ru", display_name: t("Russian") },
                  { value: "es", display_name: t("Spanish") },
                  { value: "th", display_name: t("Thai") },
                  { value: "id", display_name: t("Indonesian") },
                  { value: "ms", display_name: t("Malay") },
                  { value: "vi", display_name: t("Vietnamese") },
                  { value: "ko", display_name: t("Korean") },
                ],
                needed: true,
                fieldId: "preferred_language",
              },
              {
                field: "comments",
                key: "comments",
                label: t("Comments"),
                placeholder: t("Comments"),
                kind: "text",
                type: "text",
                needed: false,
                fieldId: "comment",
              },
            ],
          },
        ],
        [
          {
            kind: "title",
            type: "title",
            title: t("marketing-details"),
            fields: [
              {
                field: "marketing_relationship",
                key: "marketing_relationship",
                label: t(
                  "how-do-you-intend-to-establish-a-marketing-agent-relationship-with-icm-capital"
                ),
                kind: "select",
                type: "select",
                needed: true,
                half: false,
                choices: [
                  {
                    display_name: t("individual-basis"),
                    value: "individual_basis",
                  },
                ],
                fieldId: "marketing_relationship",
              },
              {
                field: "client_list_generated",
                key: "client_list_generated",
                label: t(
                  "how-do-you-intend-to-generate-client-leads-for-icm-capital"
                ),
                placeholder: t("enter-text"),
                kind: "text",
                type: "text",
                needed: true,
                fieldId: "client_list_generated",
              },
              {
                field: "clients_list_origin",
                key: "clients_list_origin",
                label: t("which-countries-will-your-leads-be-generated-from"),
                placeholder: t("enter-text"),
                kind: "text",
                type: "text",
                needed: true,
                fieldId: "clients_list_origin",
              },
              {
                field: "broker_relation_other",
                key: "broker_relation_other",
                label: t(
                  "do-you-have-an-existing-ib-marketing-agent-relationship-with-other-brokers"
                ),
                kind: "select",
                type: "select",
                needed: true,
                choices: [
                  {
                    display_name: t("Yes"),
                    value: "Yes",
                  },
                  {
                    display_name: t("No"),
                    value: "No",
                  },
                ],
                fieldId: "broker_relation_other",
              },
              {
                field: "broker_names",
                conditions: { broker_relation_other: "Yes" },
                key: "broker_names",
                label: t("broker-s-names"),
                kind: "text",
                type: "text",
                needed: true,
                fieldId: "broker_names",
                broker_details: true,
              },
              {
                field: "broker_number_of_clients",
                conditions: { broker_relation_other: "Yes" },
                key: "broker_number_of_clients",
                label: t("how-many-existing-clients-do-you-currently-have"),
                kind: "number",
                type: "number",
                needed: true,
                fieldId: "broker_number_of_clients",
                broker_details: true,
              },
              {
                field: "broker_clients_monthly_average",
                conditions: { broker_relation_other: "Yes" },
                key: "broker_clients_monthly_average",
                label: t(
                  "what-is-the-monthly-average-deposit-for-existing-clients"
                ),
                placeholder: t("enter-amount"),
                kind: "number",
                type: "number",
                needed: true,
                fieldId: "broker_clients_monthly_average",
                broker_details: true,
              },
              {
                kind: "title",
                subtitle: t("what-percentage-do-your-clients-trade-on"),
              },
              {
                field: "spot_fx",
                key: "spot_fx",
                label: t("spot-fx-metals"),
                placeholder: t("enter-percentage"),
                kind: "number",
                type: "number",
                properties: { max: 100, min: 0 },
                needed: true,
                half: true,
                icon: "icon-percentage",
                fieldId: "spot_fx",
              },
              {
                field: "commodity_futures",
                key: "commodity_futures",
                label: t("commodity-futures"),
                placeholder: t("enter-percentage"),
                kind: "number",
                type: "number",
                properties: { max: 100, min: 0 },
                needed: true,
                icon: "icon-percentage",
                half: true,
                fieldId: "commodity_futures",
              },
              {
                field: "financial_futures",
                key: "financial_futures",
                label: t("financial-futures"),
                placeholder: t("enter-percentage"),
                kind: "number",
                type: "number",
                properties: { max: 100, min: 0 },
                needed: true,
                half: true,
                icon: "icon-percentage",
                fieldId: "financial_futures",
              },
              {
                field: "off_exchange_index",
                key: "off_exchange_index",
                label: t("off-exchange-index-futures"),
                placeholder: t("enter-percentage"),
                kind: "number",
                type: "number",
                properties: { max: 100, min: 0 },
                needed: true,
                half: true,
                icon: "icon-percentage",
                fieldId: "off_exchange_index",
              },
              {
                field: "otc_equity_cfds",
                key: "otc_equity_cfds",
                label: t("otc-equity-cfds"),
                placeholder: t("enter-percentage"),
                kind: "number",
                type: "number",
                properties: { max: 100, min: 0 },
                needed: true,
                half: true,
                icon: "icon-percentage",
                fieldId: "otc_equity_cfds",
              },
              {
                kind: "title",
                subtitle: t(
                  "how-many-accounts-do-you-expect-to-introduce-over-the-next-3-months"
                ),
              },

              {
                field: "accounts_month_one",
                key: "accounts_month_one",
                label: t("month-1"),
                placeholder: t("number-of-accounts"),
                kind: "number",
                type: "number",
                properties: { min: 0 },
                needed: true,
                half: true,
                fieldId: "accounts_month_one",
              },
              {
                field: "accounts_month_two",
                key: "accounts_month_two",
                label: t("month-2"),
                placeholder: t("number-of-accounts"),
                kind: "number",
                type: "number",
                properties: { min: 0 },
                needed: true,
                half: true,
                fieldId: "accounts_month_two",
              },
              {
                field: "accounts_month_three",
                key: "accounts_month_three",
                label: t("month-3"),
                placeholder: t("number-of-accounts"),
                kind: "number",
                type: "number",
                properties: { min: 0 },
                needed: true,
                half: true,
                fieldId: "accounts_month_three",
              },
              {
                kind: "title",
                subtitle: t(
                  "what-do-you-expect-the-average-deposit-size-to-be"
                ),
              },

              {
                field: "deposits_month_one",
                key: "deposits_month_one",
                label: t("month-1"),
                placeholder: t("deposits-amount"),
                kind: "number",
                type: "number",
                properties: { min: 0 },
                needed: true,
                half: true,
                fieldId: "deposits_month_one",
              },
              {
                field: "deposits_month_two",
                key: "deposits_month_two",
                label: t("month-2"),
                placeholder: t("deposits-amount"),
                kind: "number",
                type: "number",
                properties: { min: 0 },
                needed: true,
                half: true,
                fieldId: "deposits_month_two",
              },
              {
                field: "deposits_month_three",
                key: "deposits_month_three",
                label: t("month-3"),
                placeholder: t("deposits-amount"),
                kind: "number",
                type: "number",
                properties: { min: 0 },
                needed: true,
                half: true,
                fieldId: "deposits_month_three",
              },
              {
                field: "marketing_agent_role",
                key: "marketing_agent_role",
                label: t(
                  "what-is-your-understanding-of-being-a-marketing-agent"
                ),
                placeholder: t("enter-text"),
                kind: "text",
                type: "text",
                needed: true,
                fieldId: "marketing_agent_role",
              },
              {
                field: "unpaid_deficit_balance",
                key: "unpaid_deficit_balance",
                label: t(
                  "have-you-or-any-of-the-company-principals-had-an-unpaid-deficit-balance-either-for-themselves-or-the"
                ),
                kind: "select",
                type: "select",
                needed: true,
                choices: [
                  {
                    display_name: t("Yes"),
                    value: "Yes",
                  },
                  {
                    display_name: t("No"),
                    value: "No",
                  },
                ],
                fieldId: "unpaid_deficit_balance",
              },
              {
                field: "under_investigation",
                key: "under_investigation",
                label: t(
                  "have-you-or-any-of-the-company-principals-ever-been-under-investigation-or-litigation-from-a-custome"
                ),
                kind: "select",
                type: "select",
                needed: true,
                choices: [
                  {
                    display_name: t("Yes"),
                    value: "Yes",
                  },
                  {
                    display_name: t("No"),
                    value: "No",
                  },
                ],
                fieldId: "under_investigation",
              },
              {
                field: "terms_and_conditions",
                key: "terms_and_conditions",
                label: t(
                  "by-submitting-below-i-agree-to-icm-capital-s-terms-of-business-and-related-documents-and-that-this-i"
                ),
                kind: "checkbox",
                type: "checkbox",
                needed: true,
                fieldId: "terms",
              },
            ],
          },
        ],
      ];
      if (process.env.VUE_APP_WHITELABEL === "Promena") {
        fields[0][0].fields.splice(6, 0, {
          field: "addr_zip",
          key: "addr_zip",
          label: t("Postcode"),
          placeholder: t("Postcode"),
          kind: "postcode",
          type: "postcode",
          needed: true,
          not_country: "IE",
          fieldId: "postcode",
        });
        fields[1][0].fields.splice(
          fields[1][0].fields.indexOf("terms_and_conditions"),
          1,
          {
            field: "terms_and_conditions",
            key: "terms_and_conditions",
            label:
              "<div><div>" +
              t(
                "By-submitting-below-I-confirm-that-I-have-read-and-agreed-to-Pro-MENA-Privacy-Policy"
              ) +
              "</div>" +
              "<div>" +
              t("privacy-policy-links") +
              "</div>" +
              "<div>" +
              t("English") +
              ": " +
              '<a href="https://www.promenamarkets.com/en/privacy_policy" target="_blank">www.promenamarkets.com/en/privacy_policy</a></div>' +
              "<div>" +
              t("Chinese") +
              ": " +
              '<a href="https://www.promenamarkets.com/zhs/privacy_policy" target="_blank">www.promenamarkets.com/zhs/privacy_policy</a></div>' +
              "<div>" +
              t("Arabic") +
              ": " +
              '<a href="https://www.promenamarkets.com/ar/privacy_policy" target="_blank">www.promenamarkets.com/ar/privacy_policy</a></div></div>',
            kind: "checkbox",
            type: "checkbox",
            needed: true,
            fieldId: "terms",
          }
        );
      }
      if (process.env.VUE_APP_WHITELABEL === "ICMTrader") {
        let languages = fields[0][0].fields.find(
          (obj) => obj.key === "preferred_language"
        );
        let marketingRelationship = fields[1][0].fields.find(
          (obj) => obj.key === "marketing_relationship"
        );
        let termsCondition = fields[1][0].fields.find(
          (obj) => obj.key === "terms_and_conditions"
        );
        let clientList = fields[1][0].fields.find(
          (obj) => obj.key === "client_list_generated"
        );

        if (languages !== undefined) {
          languages.choices = [
            { value: "en", display_name: t("English") },
            { value: "ar", display_name: t("Arabic") },
            { value: "fa", display_name: t("Persian") },
            { value: "zh-hans", display_name: t("chinese-simplified") },
            { value: "zh-hant", display_name: t("chinese-traditional") },
            { value: "nl", display_name: t("Dutch") },
            { value: "ru", display_name: t("Russian") },
            { value: "es", display_name: t("Spanish") },
          ];
        }
        if (marketingRelationship !== undefined) {
          marketingRelationship.label = t(
            "how-do-you-intend-to-establish-a-marketing-agent-relationship-with-wl-gravity"
          );
        }
        if (termsCondition !== undefined) {
          termsCondition.label = t(
            "by-submitting-below-I-agree-to-WL-Gravity-Terms-of-Business"
          );
        }
        if (clientList !== undefined) {
          clientList.label = t(
            "how-do-you-intend-to-generate-client-leads-for-wl-gravity"
          );
        }
      }
      if (process.env.VUE_APP_WHITELABEL === "ICMMENA") {
        var marketingRelationship = fields[1][0].fields.findIndex(
          (x) => x.key === "marketing_relationship"
        );
        var marketingRelationship2 = {
          field: "marketing_relationship",
          key: "marketing_relationship",
          label: t(
            "how-do-you-intend-to-establish-a-marketing-agent-relationship-with-icmmena"
          ),
          kind: "select",
          type: "select",
          needed: true,
          half: false,
          choices: [
            { display_name: t("individual-basis"), value: "individual_basis" },
          ],
          fieldId: "marketing_relationship",
        };
        fields[1][0].fields[marketingRelationship] = marketingRelationship2;

        var clientListGenerated = fields[1][0].fields.findIndex(
          (x) => x.key === "client_list_generated"
        );
        var clientListGenerated2 = {
          field: "client_list_generated",
          key: "client_list_generated",
          label: t("how-do-you-intend-to-generate-client-leads-for-icmmena"),
          kind: "select",
          type: "select",
          needed: true,
          half: false,
          choices: [
            { display_name: t("individual-basis"), value: "individual_basis" },
          ],
          fieldId: "client_list_generated",
        };
        fields[1][0].fields[clientListGenerated] = clientListGenerated2;

        var termsAndCondition = fields[1][0].fields.findIndex(
          (x) => x.key === "terms_and_conditions"
        );
        var termsAndCondition2 = {
          field: "terms_and_conditions",
          key: "terms_and_conditions",
          label: t(
            "by-submitting-below-i-agree-to-icm-capital-s-terms-of-business-and-related-documents-icmmena"
          ),
          kind: "checkbox",
          type: "checkbox",
          needed: true,
          fieldId: "terms",
        };
        fields[1][0].fields[termsAndCondition] = termsAndCondition2;
      }
      return fields;
    },
  },
  beforeCreate() {
    if (!localStorage.getItem("step")) {
      localStorage.setItem("step", "0");
    }
  },
  created() {
    this.$store.dispatch("countries");
  },
};
</script>
